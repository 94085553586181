/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/*
####################################################
M A I N   A P P L I C A T I O N
####################################################
*/

.crud_title {
	font-size: 24px;
	font-weight: bold;
}


.crud_sub_title {
	font-size: 18px;
	font-weight: lighter;
}




/*
####################################################
LANDING M E D I A  Q U E R I E S
####################################################
*/
 
/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 5 breakpoints
*/
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

  .non_mobile_image {
  	display: none;
  }

  .rline1 {
    margin-top:50px;
    text-align: center;
    padding-right:30px;
	padding-left:30px;
	}

	.txtline1 {
		text-align: center;
	}


	.rline2 {
	    margin-top:30px;
	}

	.txtline2 {
	    font-size:28px;
	    font-weight:bold;
	    text-align: center;
	}


	.rline3 {
	    margin-top:20px;
	    padding-right:30px;
	    padding-left:30px;
	    text-align: center;
	}

	.txtline3 {
	    font-size: 15px;
	    letter-spacing:.5px;
	}

	.rline4 {
	    margin-top:30px;
	    text-align: center;
	}

}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 768px) { 

  .non_mobile_image {
  	display: none;
  }


  .rline1 {
    margin-top:50px;
    text-align: center;
    padding-right:30px;
	padding-left:30px;
	}

	.txtline1 {
		text-align: center;
	}


	.rline2 {
	    margin-top:30px;
	}

	.txtline2 {
	    font-size:28px;
	    font-weight:bold;
	    text-align: center;
	}


	.rline3 {
	    margin-top:20px;
	    padding-right:30px;
	    padding-left:30px;
	    text-align: center;
	}

	.txtline3 {
	    font-size: 15px;
	    letter-spacing:.5px;
	}

	.rline4 {
	    margin-top:30px;
	    text-align: center;
	}

}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) {

  .non_mobile_image {
  	
  }


  .rline1 {
    margin-top:150px;
  }

  .rline2 {
	    margin-top:60px;
	}

	.txtline2 {
	    font-size:68px;
	    font-weight:bold;
	}
  

  	.rline3 {
	    margin-top:60px;
	    max-width:500px;
	}

	.txtline3 {
	    font-size: 15px;
	    letter-spacing:2px;
	}


	.rline4 {
	    margin-top:30px;
	}


}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) {

  .non_mobile_image {
  	
  }

  .rline1 {
    margin-top:150px;
  }


  .rline2 {
	    margin-top:60px;
	}

	.txtline2 {
	    font-size:68px;
	    font-weight:bold;
	}


	.rline3 {
	    margin-top:60px;
	    max-width:500px;
	}

	.txtline3 {
	    font-size: 15px;
	    letter-spacing:2px;
	}


	.rline4 {
	    margin-top:30px;
	}

}

/* X-Large devices (large desktops, less than 1400px) */
@media (min-width: 1399.98px) {

  .non_mobile_image {
  	
  }

  .rline2 {
	    margin-top:60px;
	}

	.txtline2 {
	    font-size:68px;
	    font-weight:bold;
	}

	.rline3 {
	    margin-top:60px;
	    max-width:500px;
	}

	.txtline3 {
	    font-size: 15px;
	    letter-spacing:2px;
	}


	.rline4 {
	    margin-top:30px;
	}


}


/* XX-Large devices (larger desktops) */
/* No media query since the xxl breakpoint has no upper bound on its width*/
@media (min-width: 3000px) {

  .non_mobile_image {
  	
  }


  .rline1 {
    margin-top:150px;
 }


 .rline2 {
	    margin-top:60px;
	}

	.txtline2 {
	    font-size:68px;
	    font-weight:bold;
	}


  .rline3 {
	    margin-top:60px;
	    max-width:500px;
	}

	.txtline3 {
	    font-size: 15px;
	    letter-spacing:2px;
	}


	.rline4 {
	    margin-top:30px;
	}


}

